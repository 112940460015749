import { useAppSelector } from '@thriveglobal/thrive-web-core'
import { Avo } from '@thriveglobal/thrive-web-tracking'
import React, { ComponentType, useCallback } from 'react'
import { PostThriveAudioResetActionsProps } from '../../components/features/ThriveAudioReset/PostThriveAudioReset/PostThriveAudioResetActions'

export type PostThriveAudioResetActionsTrackableProps =
    PostThriveAudioResetActionsProps & {
        utmSource?: string
    }

function withPostThriveAudioResetActionsTracking(
    PostThriveAudioResetActions: ComponentType<PostThriveAudioResetActionsTrackableProps>
) {
    return React.memo(function PostThriveAudioResetActionsTrackable(
        props: PostThriveAudioResetActionsTrackableProps
    ) {
        const { onClapped, onFavored, onShared, ...restProps } = props
        const { reset } = props
        const user = useAppSelector((state) => state.user)

        const trackOnClapped = useCallback(
            (isChecked) => {
                if (isChecked) {
                    Avo.reactionSubmitted({
                        featureType: 'content',
                        activityType: 'clap_submitted',
                        contentType: 'reset',
                        contentFormatType: 'audio',
                        contentId: reset?.id,
                        contentTitle: reset?.name,
                        contentUrl: reset?.mp3Url,
                        resetType: 'reset',
                        contentSource: 'thrive',
                        feedbackAnswer: 'positive',
                        contentSubtype: null,
                        conversationId: null,
                        feedbackQuestion: null,
                        messageType: null,
                        openAnswer: null,
                        openQuestion: null,
                        tabName: null,
                        transactionId: null
                    })
                }
            },
            [reset?.id, reset?.mp3Url, reset?.name]
        )

        const onClappedTrackable: PostThriveAudioResetActionsProps['onClapped'] =
            useCallback(
                (...args) => {
                    trackOnClapped(...args)

                    return onClapped?.call(null, ...args)
                },
                [onClapped, trackOnClapped]
            )

        const trackOnFavored = useCallback(
            (isChecked) => {
                if (isChecked) {
                    Avo.reactionSubmitted({
                        featureType: 'content',
                        activityType: 'favorite_submitted',
                        contentType: 'reset',
                        contentFormatType: 'audio',
                        contentId: reset?.id,
                        contentTitle: reset?.name,
                        contentUrl: reset?.mp3Url,
                        resetType: 'reset',
                        contentSource: 'thrive',
                        feedbackAnswer: 'positive',
                        contentSubtype: null,
                        conversationId: null,
                        feedbackQuestion: null,
                        messageType: null,
                        openAnswer: null,
                        openQuestion: null,
                        tabName: null,
                        transactionId: null
                    })
                }
            },
            [reset?.id, reset?.mp3Url, reset?.name]
        )

        const onFavoredTrackable: PostThriveAudioResetActionsProps['onFavored'] =
            useCallback(
                (...args) => {
                    trackOnFavored(...args)

                    return onFavored?.call(null, ...args)
                },
                [onFavored, trackOnFavored]
            )

        const trackOnShared = useCallback(
            () =>
                Avo.promptSelected({
                    featureType: 'content',
                    activityType: 'share_reset_selected',
                    contentId: null,
                    contentType: 'reset',
                    contentTitle: null,
                    contentSubtype: null,
                    contentFormatType: null,
                    contentUrl: null,
                    contentSource: null,
                    resetId: reset?.id,
                    resetName: reset?.name,
                    isOnboarding: null,
                    microstepId: null,
                    microstepBody: null,
                    isAutomaticCheckin: null,
                    microstepName: null,
                    checkInDate: null,
                    userId_: user?.userId,
                    displayText: null,
                    tabName: null,
                    tstPlatform: null,
                    notificationCount: null,
                    nudgeType: null
                }),
            [reset?.id, reset?.name, user?.userId]
        )

        const onSharedTrackable: PostThriveAudioResetActionsProps['onShared'] =
            useCallback(
                (...args) => {
                    trackOnShared(...args)

                    return onShared?.call(null, ...args)
                },
                [onShared, trackOnShared]
            )

        return (
            <PostThriveAudioResetActions
                {...restProps}
                onClapped={onClappedTrackable}
                onFavored={onFavoredTrackable}
                onShared={onSharedTrackable}
            />
        )
    })
}

export default withPostThriveAudioResetActionsTracking
